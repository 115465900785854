<script>
import L from "leaflet";
import {
  LMap,
  //LPolyline,
  LMarker,
  LIcon,
  LTooltip,
  LTileLayer,
  LLayerGroup,
  //LControlLayers,
  LControl,
} from "vue2-leaflet";
//import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
/**
 * Map component
 */

export default {
  props: [
    "covid",
    "insuline",
    "pni",
    "serum",
    "loader"
  ],
  mounted() {
    
    //this.centersPositions = this.centers;
  },
  components: {
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
    LLayerGroup,
    //LControlLayers,
    LControl,
    //"l-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      filterType: 1,
      noFilter: null,
      serialFilter: null,
      filterStatus: false,
      mapZoom: 6,
      mapCenter: [27.105314, -7.684968],

      covidData: [],
      pniData: [],
      insulineData: [],
      serumData: [],

      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),

      clusterOptions: {
        showCoverageOnHover: false,
      },

      imageDevice: require("@/assets/images/iconDevice.svg"),

      imageInsuline: require("@/assets/images/Insuline.svg"),
      imageSerum: require("@/assets/images/Serum.svg"),
      imagePNI: require("@/assets/images/PNI.svg"),
      imageCovid: require("@/assets/images/VaccinCovid19.svg"),

      imageInsulinegray: require("@/assets/images/Insulinegray.svg"),
      imageSerumgray: require("@/assets/images/Serumgray.svg"),
      imagePNIgray: require("@/assets/images/PNIgray.svg"),
      imageCovidgray: require("@/assets/images/VaccinCovid19gray.svg"),

      imageClear: require("@/assets/images/clearmap.svg"),
      imageReset: require("@/assets/images/resetzoom.svg"),
      zoomIn: require("@/assets/images/zoomin.svg"),
      zoomOut: require("@/assets/images/zoomout.svg"),

      //url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      //url: "http://maps.google.com/maps?file=api&amp;v=2&amp;hl=en&amp;sensor=true",
      staticAnchor: [16, 10],
      iconAnchor: [20, 40],
      iconSize: [40, 40],
      tooltipAnchor: [0, -30],

      loaderDevice: false,

      statusPNI: true,
      statusCovid: true,
      statusInsuline: true,
      statusSerum: true,
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    setZoomIn() {
      this.mapZoom = this.mapZoom + 1;
    },
    setZoomOut() {
      this.mapZoom = this.mapZoom - 1;
    },
    setResetZoom() {
      let center = [27.105314, -7.684968];
      const mapComponent = this.$refs.map;
      let start = center;
      let end = center;
      mapComponent.fitBounds([start, end], { maxZoom: 6 });
    },
    updateZoom(event) {
      this.mapZoom = event;
    },
    setClearMap() {
      this.clearDevices();
      this.provincesPositions = this.provinces;
      this.setResetZoom();
    },
    clear() {
      this.filterStatus = false;
      this.centersPositions = this.centers;
    },
    changeFilterType(type) {
      this.serialFilter = null;
      this.provinceFilter = null;
      this.filterType = type;
    },
    changeStatusPNI() {
      this.statusPNI == true ? this.statusPNI = false : this.statusPNI = true
    },
    changeStatusCovid() {
      this.statusCovid == true ? this.statusCovid = false : this.statusCovid = true
    },
    changeStatusSerum() {
      this.statusSerum == true ? this.statusSerum = false : this.statusSerum = true
    },
    changeStatusInsuline() {
      this.statusInsuline == true ? this.statusInsuline = false : this.statusInsuline = true
    },

    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    /* centers(newVal){
    newVal.map((obj) => {
      switch (obj.type) {
        case 1:
          this.centersPositionsType1.push(obj);
          break;
        case 2:
          this.centersPositionsType2.push(obj);
          return;
      }
    });
  }, */
  },
  
  
  beforeDestroy() {
    //this.$refs.map.removeLayer(this.$refs.markerCluster)
  },
};
</script>

<template>
  <div>
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div style="height: 100%">

      <l-map
        style="height: 120vh"
        ref="map"
        :zoom="mapZoom"
        v-on:update:zoom="updateZoom($event)"
        :min-zoom="5"
        :options="{ zoomControl: false }"
        :center="mapCenter"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
        
        <l-layer-group :visible="statusCovid" layerType="overlay" name="PNI">
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in covid"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageCovid" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <span style="font-weight: 550"
                >Region :
              </span>
              {{ marker.region.name }}<br />
              <span style="font-weight: 550"
                >Province :
              </span>
              {{ marker.province ? marker.province.name : null }}<br />

              <span v-if="marker.name" style="font-weight: 550"
                >Nom :
              </span>
              {{ marker.name ? marker.name : null }}<br />
              <span v-if="marker.vaccinType" style="font-weight: 550"
                >Type :
              </span>
              {{ marker.vaccinType ? marker.vaccinType : null }}<br />
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-layer-group :visible="statusInsuline" layerType="overlay" name="Serum">
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in insuline"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageInsuline" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <span style="font-weight: 550"
                >Region :
              </span>
              {{ marker.region.name }}<br />
              <span style="font-weight: 550"
                >Province :
              </span>
              {{ marker.province ? marker.province.name : null }}<br />

              <span v-if="marker.name" style="font-weight: 550"
                >Nom :
              </span>
              {{ marker.name ? marker.name : null }}<br />
              <span v-if="marker.vaccinType" style="font-weight: 550"
                >Type :
              </span>
              {{ marker.vaccinType ? marker.vaccinType : null }}<br />
            </l-tooltip>
          </l-marker>
        </l-layer-group>

         <l-layer-group
          :visible="statusPNI"
          layerType="overlay"
          name="Vaccin Covid 19"
        >
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in pni"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imagePNI" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <span style="font-weight: 550"
                >Region :
              </span>
              {{ marker.region.name }}<br />
              <span style="font-weight: 550"
                >Province :
              </span>
              {{ marker.province ? marker.province.name : null }}<br />

              <span v-if="marker.name" style="font-weight: 550"
                >Nom :
              </span>
              {{ marker.name ? marker.name : null }}<br />
              <span v-if="marker.vaccinType" style="font-weight: 550"
                >Type :
              </span>
              {{ marker.vaccinType ? marker.vaccinType : null }}<br />
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-layer-group
          :visible="statusSerum"
          layerType="overlay"
          name="Insuline"
        >
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in serum"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageSerum" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <span style="font-weight: 550"
                >Region :
              </span>
              {{ marker.region.name }}<br />
              <span style="font-weight: 550"
                >Province :
              </span>
              {{ marker.province ? marker.province.name : null }}<br />

              <span v-if="marker.name" style="font-weight: 550"
                >Nom :
              </span>
              {{ marker.name ? marker.name : null }}<br />

              <span v-if="marker.vaccinType" style="font-weight: 550"
                >Type :
              </span>
              {{ marker.vaccinType ? marker.vaccinType : null }}<br />
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-control>
          <div style="background: rgba(255, 255, 255, 0.4)">
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusCovid"
              ><img v-if="statusCovid" :src="imageCovid" width="40" />
              <img v-if="!statusCovid" :src="imageCovidgray" width="40" />Covid 19</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusInsuline"
              ><img v-if="statusInsuline" :src="imageInsuline" width="40" />
              <img v-if="!statusInsuline" :src="imageInsulinegray" width="40" />Insuline</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusPNI"
              ><img v-if="statusPNI" :src="imagePNI" width="40" />
              <img v-if="!statusPNI" :src="imagePNIgray" width="40" />Pni</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusSerum"
              ><img v-if="statusSerum" :src="imageSerum" width="40" />
              <img v-if="!statusSerum" :src="imageSerumgray" width="40" />Serum</span
            ><br />
          </div>
        </l-control>
      </l-map>
    </div>
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.4);
}
@media (max-width: 760px) {
  .searchInput {
    top: 60px;
  }
}
</style>