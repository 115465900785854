var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loader == true)?_c('div',{staticClass:"loaderDiv d-flex justify-content-center align-items-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Loading...","variant":"primary"}})],1):_vm._e(),_c('div',{staticStyle:{"height":"100%"}},[_c('l-map',{ref:"map",staticStyle:{"height":"120vh"},attrs:{"zoom":_vm.mapZoom,"min-zoom":5,"options":{ zoomControl: false },"center":_vm.mapCenter},on:{"update:zoom":function($event){return _vm.updateZoom($event)}}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"subdomains":['mt0', 'mt1', 'mt2', 'mt3']}}),_c('l-layer-group',{attrs:{"visible":_vm.statusCovid,"layerType":"overlay","name":"PNI"}},_vm._l((_vm.covid),function(marker,index){return _c('l-marker',{key:index,attrs:{"zIndexOffset":100,"draggable":false,"lat-lng":{ lat: marker.lat, lng: marker.lng },"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor,"icon-url":_vm.imageDevice}},[_c('img',{attrs:{"src":_vm.imageCovid}})]),_c('l-tooltip',{staticClass:"rounded text-secondary",attrs:{"options":{
              interactive: 'true',
              direction: 'top',
            }}},[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Region : ")]),_vm._v(" "+_vm._s(marker.region.name)),_c('br'),_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Province : ")]),_vm._v(" "+_vm._s(marker.province ? marker.province.name : null)),_c('br'),(marker.name)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Nom : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.name ? marker.name : null)),_c('br'),(marker.vaccinType)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Type : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.vaccinType ? marker.vaccinType : null)),_c('br')])],1)}),1),_c('l-layer-group',{attrs:{"visible":_vm.statusInsuline,"layerType":"overlay","name":"Serum"}},_vm._l((_vm.insuline),function(marker,index){return _c('l-marker',{key:index,attrs:{"zIndexOffset":100,"draggable":false,"lat-lng":{ lat: marker.lat, lng: marker.lng },"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor,"icon-url":_vm.imageDevice}},[_c('img',{attrs:{"src":_vm.imageInsuline}})]),_c('l-tooltip',{staticClass:"rounded text-secondary",attrs:{"options":{
              interactive: 'true',
              direction: 'top',
            }}},[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Region : ")]),_vm._v(" "+_vm._s(marker.region.name)),_c('br'),_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Province : ")]),_vm._v(" "+_vm._s(marker.province ? marker.province.name : null)),_c('br'),(marker.name)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Nom : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.name ? marker.name : null)),_c('br'),(marker.vaccinType)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Type : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.vaccinType ? marker.vaccinType : null)),_c('br')])],1)}),1),_c('l-layer-group',{attrs:{"visible":_vm.statusPNI,"layerType":"overlay","name":"Vaccin Covid 19"}},_vm._l((_vm.pni),function(marker,index){return _c('l-marker',{key:index,attrs:{"zIndexOffset":100,"draggable":false,"lat-lng":{ lat: marker.lat, lng: marker.lng },"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor,"icon-url":_vm.imageDevice}},[_c('img',{attrs:{"src":_vm.imagePNI}})]),_c('l-tooltip',{staticClass:"rounded text-secondary",attrs:{"options":{
              interactive: 'true',
              direction: 'top',
            }}},[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Region : ")]),_vm._v(" "+_vm._s(marker.region.name)),_c('br'),_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Province : ")]),_vm._v(" "+_vm._s(marker.province ? marker.province.name : null)),_c('br'),(marker.name)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Nom : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.name ? marker.name : null)),_c('br'),(marker.vaccinType)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Type : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.vaccinType ? marker.vaccinType : null)),_c('br')])],1)}),1),_c('l-layer-group',{attrs:{"visible":_vm.statusSerum,"layerType":"overlay","name":"Insuline"}},_vm._l((_vm.serum),function(marker,index){return _c('l-marker',{key:index,attrs:{"zIndexOffset":100,"draggable":false,"lat-lng":{ lat: marker.lat, lng: marker.lng },"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor,"icon-url":_vm.imageDevice}},[_c('img',{attrs:{"src":_vm.imageSerum}})]),_c('l-tooltip',{staticClass:"rounded text-secondary",attrs:{"options":{
              interactive: 'true',
              direction: 'top',
            }}},[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Region : ")]),_vm._v(" "+_vm._s(marker.region.name)),_c('br'),_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Province : ")]),_vm._v(" "+_vm._s(marker.province ? marker.province.name : null)),_c('br'),(marker.name)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Nom : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.name ? marker.name : null)),_c('br'),(marker.vaccinType)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Type : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.vaccinType ? marker.vaccinType : null)),_c('br')])],1)}),1),_c('l-control',[_c('div',{staticStyle:{"background":"rgba(255, 255, 255, 0.4)"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},on:{"click":_vm.changeStatusCovid}},[(_vm.statusCovid)?_c('img',{attrs:{"src":_vm.imageCovid,"width":"40"}}):_vm._e(),(!_vm.statusCovid)?_c('img',{attrs:{"src":_vm.imageCovidgray,"width":"40"}}):_vm._e(),_vm._v("Covid 19")]),_c('br'),_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},on:{"click":_vm.changeStatusInsuline}},[(_vm.statusInsuline)?_c('img',{attrs:{"src":_vm.imageInsuline,"width":"40"}}):_vm._e(),(!_vm.statusInsuline)?_c('img',{attrs:{"src":_vm.imageInsulinegray,"width":"40"}}):_vm._e(),_vm._v("Insuline")]),_c('br'),_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},on:{"click":_vm.changeStatusPNI}},[(_vm.statusPNI)?_c('img',{attrs:{"src":_vm.imagePNI,"width":"40"}}):_vm._e(),(!_vm.statusPNI)?_c('img',{attrs:{"src":_vm.imagePNIgray,"width":"40"}}):_vm._e(),_vm._v("Pni")]),_c('br'),_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},on:{"click":_vm.changeStatusSerum}},[(_vm.statusSerum)?_c('img',{attrs:{"src":_vm.imageSerum,"width":"40"}}):_vm._e(),(!_vm.statusSerum)?_c('img',{attrs:{"src":_vm.imageSerumgray,"width":"40"}}):_vm._e(),_vm._v("Serum")]),_c('br')])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }