<script>
import Layout from "../../layouts/main";

import HorizontalMenu from "../../../components/horizontal-navbar-2.vue";
import CentersMap from "./centers-map";

import {
  layoutComputed,
  authComputed,
  layoutMethods,
  devicesMethods,
  devicesComputed,
} from "@/state/helpers";
/**
 * Devices component
 */
export default {
  page: {
    title: "Health-Centers",
    meta: [{ name: "Health-Centers" }],
  },
  components: {
    Layout,
    HorizontalMenu,
    CentersMap,
  },
  mounted() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "hidden";
    this.changeMenuType();
  },
  created() {
     this.retrieveDevices();
    /*this.retrieveDevices(2);
    this.retrieveDevices(3);
    this.retrieveDevices(4); */
  },
  data() {
    return {
      title: "Health-Centers",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Health-Centers",
          active: true,
        },
      ],

      loaderStatus: false,

      covidData: [],
      insulineData: [],
      pniData: [],
      serumData: [],

      route: "/health-centers",
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...devicesComputed,
    userRole() {
      return this.loggedIn.user.role;
    },
  },
  methods: {
    ...layoutMethods,
    ...devicesMethods,
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
    retrieveDevices() {
      this.getDevicesBy({
        limit: 7000,
        page: 1,
      }).then((devices) => {
        console.log(devices);
        devices.results.map(device => {
          if(device.vaccinType){
            if(device.vaccinType == "COVID19" && this.covidData.length < 500) this.covidData.push(device)
            if(device.vaccinType == "INSULINE" && this.insulineData.length < 500) this.insulineData.push(device)
            if(device.vaccinType == "PNI" && this.pniData.length < 500) this.pniData.push(device)
            if(device.vaccinType == "SERUM" && this.serumData.length < 500) this.serumData.push(device)
          }
        });
      });
    },
  },
  watch: {},
  beforeDestroy() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "visible";
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <Horizontal-menu class="horiMenu" v-bind:page="route" />
    <CentersMap
      v-bind:covid="covidData"
      :insuline="insulineData"
      :pni="pniData"
      :serum="serumData"
      :loader="loaderStatus"
    />
  </Layout>
</template>
<style scoped>
@media (min-width: 761px) {
  div /deep/ .footer {
    display: none;
  }
  div /deep/ #page-topbar {
    display: none;
  }
  div /deep/ .vertical-menu {
    display: none;
  }
}

@media (max-width: 760px) {
  .horiMenu {
    display: none;
  }
}

div /deep/ .container-fluid {
  padding: 0px;
}
div /deep/ .page-content {
  /*padding-top: 60px;*/
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
div /deep/ .page-content {
  padding-top: 0px;
}
div /deep/ .main-content {
  margin-left: 0px;
}
</style>